import * as React from 'react';
import { Markdown, SubPageLayout, SubSection, SubSections } from '../components';
import missionData from '../data/mission.json';

export const MissionPageInternal = (mission: typeof missionData) => {
  return (
    <SubPageLayout
      title="Mission"
      imageSrc={mission.headerImageSrc}
    >
      <SubSections>
        {[
          <SubSection key="our-mission" anchor="our-mission" headerLabel="Our Mission">
            <h4 className="text-center">“To excel in providing services to all present and prospective members in a friendly and helpful manner.”</h4>
          </SubSection>,
          <SubSection key="our-story" anchor="our-story" headerLabel="Our Story">
            <Markdown source={mission.ourStory} />
          </SubSection>,
          <SubSection key="cu-for-me" anchor="cu-for-me" headerLabel="The CU for ME Difference">
            <Markdown source={mission.cuForMe} />
          </SubSection>,
          <SubSection key="how-to-join" anchor="how-to-join" headerLabel="How to Join">
            <Markdown source={mission.howToJoin} />
          </SubSection>,
          <SubSection key="scholarship" anchor="scholarship" headerLabel="The Longmore Esson Scholarship">
            <Markdown source={mission.scholarship} />
          </SubSection>,
        ]}
      </SubSections>
    </SubPageLayout>
  );
};

export default () => <MissionPageInternal {...missionData} />;
